import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import { ThemeProvider, injectGlobal } from 'styled-components';
import styledNormalize from 'styled-normalize';
import { LiveChatLoaderProvider, Intercom } from 'react-live-chat-loader'
import Theme from '@rotaready/frecl/build/Theme';

import Header from '../header/Header';
import Footer from '../Footer';
import '../../utilities/font-awesome';
import './3rdParty.css';

const MOBILE_HEADER_HEIGHT = 80;
const DESKTOP_HEADER_HEIGHT = 80;
const NAV_BREAKPOINT = 926;

injectGlobal`
  ${styledNormalize}

  @font-face {
    font-family: 'URWGeometric';
    src: url('/fonts/URWGeometric-Regular.woff2') format('woff2'),
      url('/fonts/URWGeometric-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'URWGeometric';
    src: url('/fonts/URWGeometric-SemiBold.woff2') format('woff2'),
      url('/fonts/URWGeometric-SemiBold.woff') format('woff');
    font-weight: 600;
    font-display: swap;
  }

  @font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-Medium.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
  }

  .tippy-tooltip.rr-theme {
    box-shadow: 0 0 4px 1px rgba(71, 91, 104, 0.1);
    background-color: white;
    outline: none;

    > .tippy-content {
      padding: 0;
    }
  }



  .headroom--pinned {
    z-index: 2 !important;
  }

  body {
    overflow-x: hidden;
  }

  header {
    height: ${MOBILE_HEADER_HEIGHT}px;
    @media (min-width: ${NAV_BREAKPOINT}px) {
      height: ${DESKTOP_HEADER_HEIGHT}px;
    }
  }
`;

const Body = ({ children, header, displayHeader, displayFooter }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={() => (
      <ThemeProvider theme={Theme}>
        <div id="root">
          {displayHeader
          && (
            <Header invertColors={header.invertColors} backgroundColor={header.backgroundColor} />
          )}
          <main>
            <LiveChatLoaderProvider providerKey="kd3kz4na" provider="intercom">
            {children}
            <Intercom color="#0d51ff"/>
            </LiveChatLoaderProvider>
          </main>
          {displayFooter && <Footer /> }
        </div>
      </ThemeProvider>
    )}
  />
);

Body.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.shape(Header.propTypes),
  displayHeader: PropTypes.bool,
  displayFooter: PropTypes.bool,
};

Body.defaultProps = {
  header: Header.defaultProps,
  displayHeader: true,
  displayFooter: true,
};

export default Body;
